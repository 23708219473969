import React from 'react'

import { Helmet } from 'gatsby-plugin-react-i18next'

const JsonLd: React.FC = ({ children }) => (
  <Helmet>
    <script type="application/ld+json">{JSON.stringify(children)}</script>
  </Helmet>
)

export default JsonLd
