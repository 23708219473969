import React, { useContext, useEffect, useState } from 'react'

import { useTranslation, Link } from 'gatsby-plugin-react-i18next'
import { isEqual, kebabCase, sortBy } from 'lodash'
import InnerImageZoom from 'react-inner-image-zoom'

import StoreContext from '../../context/store'
import { Description, Photo, Tag, Breadcrumb } from '../../styled'

import { Button, ButtonGroup, LinkButton } from '../../styled/button'
import Like from '../wishlist/like'

import Color from './color'
import Price from './price'
import {
  Added,
  OptionContainer,
  ProductViewContainer,
  ProductViewPreviewContainer,
  OptionButton,
  ProductViewImagePreviewContainer,
} from './styled'

const Option: React.FC<{
  option: any
  variant: any
  onChange: (options: any) => void
}> = ({ option, variant, onChange }) => {
  const { t } = useTranslation()
  const { selectedOptions } = variant
  const handleChange = (value: string) =>
    onChange([
      ...selectedOptions.filter(
        (currentOption: any) => currentOption.name !== option.name
      ),
      { name: option.name, value },
    ])
  const isSelected = (value: string) =>
    !!selectedOptions.find((currentOption: any) =>
      isEqual(currentOption, { name: option.name, value })
    )

  return (
    <OptionContainer>
      <h2>{t(option.name)}</h2>
      {option.values.map((value: string) =>
        option.name === 'Color' ? (
          <LinkButton
            key={value}
            onClick={() => handleChange(value)}
            aria-label={value}
          >
            <Color color={value} selected={isSelected(value)} />
          </LinkButton>
        ) : (
          <OptionButton
            key={value}
            onClick={() => handleChange(value)}
            selected={isSelected(value)}
            aria-label={value}
          >
            <span>{value}</span>
          </OptionButton>
        )
      )}
    </OptionContainer>
  )
}

const View: React.FC<{ product: any }> = ({ product }) => {
  const { t } = useTranslation()
  const {
    variants,
    variants: [initialVariant],
  } = product
  const [variant, setVariant] = useState({ ...initialVariant })
  const [quantity, setQuantity] = useState(1)
  const [currentImage, setCurrentImage] = useState<any>()
  const [saving, setSaving] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const {
    addVariantToCart,
    store: { adding },
  } = useContext(StoreContext)
  const handleChangeVariant = (newOptions: any) => {
    setShowMessage(false)
    const newVariant = variants?.find((currentVariant: any) =>
      isEqual(
        sortBy(currentVariant.selectedOptions, ['name']),
        sortBy(newOptions, ['name'])
      )
    )
    if (newVariant) {
      setVariant(newVariant)
      setCurrentImage(newVariant?.image)
    }
  }

  useEffect(() => {
    if (saving && !adding) {
      setSaving(false)
      setShowMessage(true)
      setTimeout(() => {
        setShowMessage(false)
      }, 5000)
    }
  }, [adding, saving])

  return (
    <ProductViewContainer>
      {product.productType ? (
        <Breadcrumb>
          <Link to={`/shop/${kebabCase(product.productType)}`}>
            {t(`type.${product.productType}`)}
          </Link>
        </Breadcrumb>
      ) : undefined}
      <ProductViewImagePreviewContainer>
        {product.images.map((image: any) => (
          <Button
            appearance="ghost"
            key={image.id}
            onClick={() => setCurrentImage(image)}
            className={`${currentImage?.id === image?.id ? 'active' : ''}`}
          >
            <img
              srcSet={image?.localFile?.childImageSharp?.fluid?.srcSet}
              src={image?.localFile?.childImageSharp?.fluid?.src}
              alt={product?.title}
              title={product?.title}
            />
          </Button>
        ))}
      </ProductViewImagePreviewContainer>
      <ProductViewPreviewContainer>
        <div className="product-images">
          <Photo>
            <InnerImageZoom
              srcSet={
                currentImage?.localFile?.childImageSharp.fluid.srcSet ??
                variant?.image?.localFile?.childImageSharp?.fluid?.srcSet
              }
              src={
                currentImage?.localFile?.childImageSharp.fluid.src ??
                variant?.image?.localFile?.childImageSharp?.fluid?.src
              }
              alt={`${product.title} - ${variant.title}`}
              title={`${product.title} - ${variant.title}`}
              zoomSrc={currentImage?.originalSrc ?? variant?.image?.originalSrc}
              zoomType="hover"
            />
          </Photo>
        </div>
        <div className="product-description">
          <div style={{ marginBottom: '30px' }}>
            <h2 className="title" property="name">
              {product.title}
            </h2>
            <Like currentProduct={product} variant={variant} large />
          </div>
          {!variant?.availableForSale ? (
            <Tag danger>{t('outOfStock')}</Tag>
          ) : undefined}
          <Price
            price={variant.price}
            compareAtPrice={variant.compareAtPrice}
            large
          />
          {sortBy(product.options, ['name']).map((option: any) => (
            <Option
              key={option.name}
              option={option}
              variant={variant}
              onChange={handleChangeVariant}
            />
          ))}

          <ButtonGroup>
            <input
              type="number"
              min="1"
              onChange={(e: any) => setQuantity(e.target.value)}
              value={quantity}
            />
            <Button
              onClick={() => {
                setSaving(true)
                addVariantToCart(variant.shopifyId, quantity)
              }}
              disabled={adding || !variant?.availableForSale}
            >
              {t('product.add')}
            </Button>
          </ButtonGroup>
          <br />
          {showMessage && <Added>{t('product.added')}</Added>}
          <Description>
            <h2>{t('product.description')}</h2>
            <p dangerouslySetInnerHTML={{ __html: product.descriptionHtml }} />
          </Description>
        </div>
      </ProductViewPreviewContainer>
    </ProductViewContainer>
  )
}

export default View
