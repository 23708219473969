import { cloneDeep, isArray, isObject, isString, trim } from 'lodash'

export default function sanitize(data: any) {
  const sanitizedData = cloneDeep(data)
  Object.keys(sanitizedData).forEach(key => {
    const value = sanitizedData[key]
    if (value === '' || value === null || value === undefined)
      delete sanitizedData[key]
    else if (isObject(value)) sanitizedData[key] = sanitize(value)
    else if (isArray(value))
      sanitizedData[key] = value.map(obj => sanitize(obj))
    else if (isString(value)) sanitizedData[key] = trim(value)
  })
  return sanitizedData
}
