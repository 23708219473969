import React, { useContext, useState } from 'react'

import { BiX } from '@react-icons/all-files/bi/BiX'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

import StoreContext from '../../context/store'
import { Container, Title } from '../../styled'
import { Button, ButtonGroup } from '../../styled/button'
import { List } from '../index'

const CartContainer = styled(Container)`
  padding: 16px;

  dt {
    padding: 0 16px;
    background-color: ${({ theme }) => theme.colors.text};
    color: ${({ theme }) => theme.colors.background};
    font-family: quasimoda, sans-serif;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.33px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  dd {
    font-size: 11px;
    font-family: quasimoda, sans-serif;
    padding: 0 16px;
    text-align: right;
  }

  .discount {
    color: ${({ theme }) => theme.colors.pink};
  }
`

const Cart = () => {
  const { t } = useTranslation()
  const {
    addDiscount,
    removeDiscount,
    store: { checkout, client, updating },
  } = useContext(StoreContext)
  const [code, setCode] = useState('')
  const toRow = ({ variant, title, quantity, id }: any) => ({
    handle: variant.product.handle,
    title,
    variantTitle: variant.title,
    price: variant.price,
    availableForSale: variant.available,
    compareAtPrice: variant.compareAtPrice,
    id,
    image: variant.image.src,
    quantity,
  })
  const [discount] = checkout?.discountApplications ?? []
  const totals = [
    {
      label: t('cart.discount'),
      value: (
        <Button
          appearance="link"
          className="discount"
          onClick={() => removeDiscount(client)}
        >
          <strong>{discount?.code?.toUpperCase()}</strong>{' '}
          <i>{`(-${discount?.value?.percentage}%)`}</i> <BiX size={24} />
        </Button>
      ),
      hidden: !discount || !discount.applicable,
    },
    {
      label: t('cart.subtotal'),
      value: t('price', { amount: checkout.subtotalPrice }),
    },
    {
      label: t('cart.tax'),
      value: t('price', { amount: checkout.totalTax }),
    },
    {
      label: t('cart.price'),
      value: t('price', { amount: checkout.totalPrice }),
      strong: true,
    },
  ]

  return (
    <CartContainer>
      <div className="review">
        <h2>
          <Title>{t('cart.title')}</Title>
        </h2>
      </div>
      <List
        empty={t('cart.empty')}
        products={checkout?.lineItems?.map(toRow) ?? []}
        withQuantity
      />
      {!discount || !discount.applicable ? (
        <ButtonGroup>
          <input
            placeholder={t('cart.enterDiscount')}
            onChange={(e: any) => setCode(e.target.value)}
            value={code}
          />
          <Button
            onClick={() => {
              addDiscount(client, code)
            }}
            disabled={updating}
          >
            {t('cart.applyDiscount')}
          </Button>
        </ButtonGroup>
      ) : undefined}
      <div className="payment">
        <dl>
          {totals.map(
            ({ hidden, label, value, strong }) =>
              !hidden && (
                <div key={label}>
                  <dt>{label}</dt>
                  <dd>{strong ? <strong>{value}</strong> : value}</dd>
                </div>
              )
          )}
        </dl>
        <Button
          onClick={() => {
            window.open(checkout.webUrl)
          }}
          disabled={checkout?.lineItems?.length === 0}
        >
          {t('cart.checkout')}
        </Button>
      </div>
    </CartContainer>
  )
}

export default Cart
