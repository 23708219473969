import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

import { Container } from '../../styled'
import { Button } from '../../styled/button'

const BrandAmbassadorContainer = styled.a`
  max-width: 1368px;
  width: 100%;
  margin: 30px auto;
  display: block;

  button {
    margin-top: 10px;
    height: 42px;
  }

  .brand-ambassador-container {
    background: #fff1f6;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.black};
    flex: 1 0 auto;
    padding: 30px;

    @media only screen and (max-width: 1200px) {
      flex-direction: column;
    }

    ${Container} {
      padding: 0 40px;
      width: 100%;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      text-align: center;

      h2 {
        font-family: quasimoda-2, quasimoda, sans-serif;
        font-weight: bold;
        font-size: 48px;
        text-transform: uppercase;
      }

      p {
        font-size: 18px;
      }

      @media only screen and (max-width: 740px) {
        padding: 20px;

        h2 {
          font-size: 36px;
        }
      }
    }

    .brand-image-container {
      text-align: center;
      padding: 40px;

      img {
        display: block;
        width: 100%;
        filter: brightness(0.9);
        transition: all 0.3s linear;

        &:hover {
          filter: brightness(1);
        }
      }
    }
  }
`

const BrandAmbassador = () => {
  const { t } = useTranslation()
  const { file } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "brand2.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <BrandAmbassadorContainer
      href="/become-an-ambassador"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="brand-ambassador-container">
        <Container>
          <h2>{t('ambassador.title')}</h2>
          <p>
            <Trans i18nKey="ambassador.description" components={[<strong />]} />
          </p>
          <Button>{t('ambassador.joinNow')}</Button>
        </Container>
        <div className="brand-image-container ">
          <img
            src={file?.childImageSharp?.fluid?.src}
            srcSet={file?.childImageSharp?.fluid?.srcSet}
            alt={t('ambassador.title')}
            title={t('ambassador.title')}
          />
        </div>
      </div>
    </BrandAmbassadorContainer>
  )
}

export default BrandAmbassador
