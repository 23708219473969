import { TFunction } from 'i18next'

import { URL } from '../config'

export const toBreadcrumbJsonLd =
  (t: TFunction) =>
  (product: any = {}) => ({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: t(`type.${product.productType}`),
        item: `${URL}/shop`,
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: product.title,
        item: `${URL}/shop/${product.handle}`,
      },
    ],
  })

export const toProductJsonLd = (product: any = {}) => {
  const [initialVariant] = product?.variants ?? []

  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: product.title,
    image: product.images?.map(({ originalSrc }: any) => originalSrc),
    description: product.description,
    sku: product.shopifyId,
    brand: {
      '@type': 'Brand',
      name: 'BYM GENERATION',
    },
    // review: {
    //   '@type': 'Review',
    //   reviewRating: {
    //     '@type': 'Rating',
    //     ratingValue: '4',
    //     bestRating: '5',
    //   },
    //   author: {
    //     '@type': 'Person',
    //     name: 'Fred Benson',
    //   },
    // },
    // aggregateRating: {
    //   '@type': 'AggregateRating',
    //   ratingValue: '4.4',
    //   reviewCount: '89',
    // },
    offers: {
      '@type': 'Offer',
      url: `${URL}/shop/${product.handle}`,
      priceCurrency: 'EUR',
      price: initialVariant.price,
      itemCondition: 'https://schema.org/NewCondition',
      availability: product.availableForSale
        ? 'https://schema.org/InStock'
        : 'https://schema.org/PreOrder',
    },
  }
}
