import React, { ReactNode, useEffect, useState } from 'react'

import { BiMenu } from '@react-icons/all-files/bi/BiMenu'
import { navigate } from 'gatsby'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import { useWindowScroll } from 'react-use'

import { Container } from '../../styled'

import { LinkButton } from '../../styled/button'

import BYMParis from './assets/bymparis.svg'
import Shop from './shop'
import ShopNavigation from './shop-navigation'
import { NavContainer, NavBody, NavContent } from './styled'

const Nav: React.FC<{ fixed?: boolean }> = ({ fixed }) => {
  const { t } = useTranslation()
  const { y } = useWindowScroll()
  const [redirect, setRedirect] = useState('')
  const [className, setClassName] = useState((fixed && 'fixed white') || '')
  const [content, setContent] = useState<ReactNode>()
  const [active, setActive] = useState<number>()
  const clear = () => {
    setActive(-1)
    setContent(undefined)
  }
  const displayMenu = () => {
    if (active === 1) clear()
    else {
      setActive(1)
      setContent(
        <Shop
          onClick={() => {
            setActive(-1)
            setContent(undefined)
          }}
        />
      )
    }
  }
  const navItems = [
    {
      onClick: () => {
        setActive(0)
        setRedirect(t('nav.news.url'))
      },
      onMouseEnter: () => clear(),
      title: t('nav.news.title'),
      classNames: 'large-only',
    },
    {
      onClick: displayMenu,
      title: t('nav.shop.title'),
      classNames: 'large-only',
      'data-type': 'shop-btn',
    },
    {
      onClick: displayMenu,
      title: t('nav.shop.title'),
      icon: <BiMenu size={24} />,
      classNames: 'small-only',
      'data-type': 'shop-btn',
    },
    {
      onClick: () => {
        setActive(3)
        setRedirect('/about')
      },
      onMouseEnter: () => clear(),
      title: t('aboutUs'),
      classNames: 'large-only',
    },
    // {
    //   onClick: () => {
    //     setActive(3)
    //     setRedirect(t('nav.bym.url'))
    //   },
    //   onMouseEnter: () => setContent(undefined),
    //   title: t('nav.bym.title'),
    // },
    // {
    //   onClick: () => {
    //     setActive(4)
    //     setRedirect(t('nav.blog.url'))
    //   },
    //   onMouseEnter: () => setContent(undefined),
    //   title: t('nav.blog.title'),
    // },
  ]

  useEffect(() => {
    setClassName(
      y < 300 && fixed
        ? 'fixed white'
        : (y >= 300 && fixed && 'fixed top') || ''
    )
  }, [fixed, y])

  if (redirect) {
    navigate(redirect)
    setRedirect('')
    setContent(undefined)
  }

  return (
    <NavBody className={className}>
      <ShopNavigation />
      <NavContainer className={className}>
        <Container>
          <Link to="/" aria-label={t('nav.home')}>
            <h1 style={{ margin: 0 }}>
              <span className="hidden">BYM GENERATION</span>
              <BYMParis height={38} />
            </h1>
          </Link>
          <ul>
            {navItems.map(
              (
                { icon, classNames, title, onClick, onMouseEnter, ...rest },
                index
              ) => (
                <li key={`${title}_${index}`} className={classNames}>
                  <LinkButton
                    onClick={onClick}
                    onMouseEnter={onMouseEnter}
                    aria-label={title}
                    active={active === index}
                    {...rest}
                  >
                    {icon || title}
                  </LinkButton>
                </li>
              )
            )}
          </ul>
        </Container>
      </NavContainer>
      {content && <NavContent>{content}</NavContent>}
    </NavBody>
  )
}

export default Nav
