import React, { useEffect, useState } from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'

import { Fade } from 'react-awesome-reveal'

import { useWindowSize } from 'react-use'

import { Slider } from '../../styled'

import SWIMVideo from './assets/swim.mp4'
import SWIMMinVideo from './assets/swim_min.mp4'
import { IntroContainer } from './styled'

const Intro = () => {
  const { width } = useWindowSize()
  const { t } = useTranslation()
  const [currentSlide, setCurrentSlide] = useState(0)
  const [currentMiniSlide, setCurrentMiniSlide] = useState(0)
  const data = useStaticQuery(graphql`
    query {
      swim_min: file(relativePath: { eq: "swim_min.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      swim: file(relativePath: { eq: "swim.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      swim2: file(relativePath: { eq: "swim3.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      swim2_min: file(relativePath: { eq: "swim2_min.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sweatshirt_min: file(relativePath: { eq: "sweatmin.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const slides = [
    <Fade>
      <IntroContainer>
        <Link to="/news">
          <video
            className="large-only"
            muted
            autoPlay
            loop
            playsInline
            preload="auto"
          >
            <source className="large-only" src={SWIMVideo} type="video/mp4" />
          </video>
          <video
            className="small-only"
            muted
            autoPlay
            loop
            playsInline
            preload="auto"
          >
            <source
              className="small-only"
              src={SWIMMinVideo}
              type="video/mp4"
            />
          </video>
        </Link>
      </IntroContainer>
    </Fade>,
    <Fade>
      <IntroContainer>
        <Link to="/news">
          <p>
            <span className="collection">{t('intro.activeChic')}</span>
          </p>
          <Image
            className="large-only"
            fluid={data?.swim?.childImageSharp?.fluid}
            alt={t('sale.description')}
            title={t('sale.description')}
          />
          <Image
            className="small-only"
            fluid={data?.swim2_min?.childImageSharp?.fluid}
            alt={t('sale.description')}
            title={t('sale.description')}
          />
        </Link>
      </IntroContainer>
    </Fade>,
    <Fade>
      <IntroContainer>
        <Link to="/news">
          <p>
            <span className="collection">{t('intro.activeChic')}</span>
          </p>
          <Image
            className="large-only"
            fluid={data?.swim2?.childImageSharp?.fluid}
            alt={t('sale.description')}
            title={t('sale.description')}
          />
          <Image
            className="small-only"
            fluid={data?.swim_min?.childImageSharp?.fluid}
            alt={t('sale.description')}
            title={t('sale.description')}
          />
        </Link>
      </IntroContainer>
    </Fade>,
  ]
  const slidesMini = [
    <Fade>
      <IntroContainer>
        <Link to="/news">
          <p>
            <span className="collection">{t('intro.activeChic')}</span>
          </p>
          <Image
            fluid={data?.swim_min?.childImageSharp?.fluid}
            alt={t('sale.description')}
            title={t('sale.description')}
          />
        </Link>
      </IntroContainer>
    </Fade>,
    <Fade>
      <IntroContainer>
        <Link to="/news">
          <p>
            <span className="collection">{t('intro.activeChic')}</span>
          </p>
          <Image
            fluid={data?.sweat?.childImageSharp?.fluid}
            alt={t('sale.description')}
            title={t('sale.description')}
          />
        </Link>
      </IntroContainer>
    </Fade>,
    <Fade>
      <IntroContainer>
        <Link to="/news">
          <p>
            <span className="collection">{t('intro.activeChic')}</span>
          </p>
          <Image
            fluid={data?.sweatshirt_min?.childImageSharp?.fluid}
            alt={t('sale.description')}
            title={t('sale.description')}
          />
        </Link>
      </IntroContainer>
    </Fade>,
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentSlide === slides.length - 1) setCurrentSlide(0)
      else setCurrentSlide(currentSlide + 1)
      if (currentMiniSlide === slidesMini.length - 1) setCurrentMiniSlide(0)
      else setCurrentMiniSlide(currentMiniSlide + 1)
    }, 15000)
    return () => {
      clearInterval(interval)
    }
  }, [currentMiniSlide, currentSlide, slides?.length, slidesMini?.length])
  if (
    width <= 740 ||
    (typeof navigator !== 'undefined' &&
      /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator?.userAgent))
  )
    return <Slider>{slidesMini[currentMiniSlide]}</Slider>

  return <Slider>{slides[currentSlide]}</Slider>
}

export default Intro
