export const URL = 'https://bymparis.com'

export const database = [
  {
    store: 'wishlist',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'handle', keypath: 'handle', options: { unique: false } },
      { name: 'shopifyId', keypath: 'shopifyId', options: { unique: true } },
      { name: 'title', keypath: 'title', options: { unique: false } },
      {
        name: 'variantTitle',
        keypath: 'variantTitle',
        options: { unique: false },
      },
      { name: 'image', keypath: 'image', options: { unique: false } },
      { name: 'price', keypath: 'price', options: { unique: false } },
      {
        name: 'compareAtPrice',
        keypath: 'compareAtPrice',
        options: { unique: false },
      },
      {
        name: 'availableForSale',
        keypath: 'availableForSale',
        options: { unique: false },
      },
    ],
  },
]
