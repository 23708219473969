import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'
import { useTranslation, Link } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

import { Container } from '../../styled'

const PromoTitle = styled.div`
  text-align: center;
  padding: 0px 20px;
  background: black;
  color: ${({ theme }) => theme.colors.white};
`
const PromoContainer = styled.div`
  background: black;
  text-transform: uppercase;
  font-family: quasimoda-2, quasimoda, sans-serif;
  font-weight: bold;
  font-style: italic;
  width: 100%;
  margin: 60px auto;
  img {
    filter: brightness(0.9) contrast(1.1);
  }

  a {
    display: block;
    position: relative;
  }

  span {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    z-index: 3;
    font-size: 96px;
    color: ${({ theme }) => theme.colors.white};
    text-shadow: 5px 5px 2px rgba(0, 0, 0, 0.7);

    @media only screen and (max-width: 740px) {
      font-size: 48px;
    }
  }
`

const Promo = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "promo.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <PromoContainer>
      <Container>
        <PromoTitle>{t('promo.coupon', { coupon: 'BYMG001' })}</PromoTitle>
        <Link to="/shop">
          <span>{t('promo.shopNow')}</span>
          <Image
            fluid={data?.file?.childImageSharp?.fluid}
            alt={t('promo.title')}
            title={t('promo.title')}
          />
        </Link>
      </Container>
    </PromoContainer>
  )
}

export default Promo
