import { useEffect, useState } from 'react'

import firebase from 'gatsby-plugin-firebase'

export default function useAuth() {
  const [logged, setLogged] = useState(false)
  const [user, setUser] = useState<any>()

  useEffect(() => {
    firebase
      .auth()
      .signInAnonymously()
      .then(({ user: userData }) => {
        setUser(userData)
        setLogged(true)
      })
      .catch((error: any) => {
        setLogged(false)
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }, [])

  return { logged, user }
}
