import styled from 'styled-components'

import { Container, Description } from '../../../styled'
import { Button, ButtonGroup, LinkButton } from '../../../styled/button'

export const ProductViewContainer = styled(Container)`
  padding: 30px;
  position: relative;

  ${ButtonGroup} {
    margin-top: 5px;
  }
`

export const ProductViewImagePreviewContainer = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 20px;
  width: 100%;
  height: 100px;
  white-space: nowrap;

  button {
    padding: 0;
    margin: 0;
    border: 0;
    height: 100px;

    &:not(:last-of-type) {
      margin-right: 20px;
    }

    img {
      filter: brightness(0.9) contrast(1.1);
      height: 100%;
    }

    &:not(.active) img {
      filter: brightness(0.9) contrast(0.8) saturate(0);
      opacity: 0.9;
    }
  }
`

export const ProductViewPreviewContainer = styled.div`
  display: flex;
  align-items: flex-start;

  .product-images {
    display: flex;
    flex-direction: row-reverse;
  }

  .product-description {
    max-width: 800px;
    width: 100%;
    margin-left: 20px;

    .title {
      font-family: quasimoda-2, quasimoda, sans-serif;
      font-weight: bold;
      margin-top: 0;
      text-transform: uppercase;
      font-size: 32px;
    }

    ${Description} {
      margin-top: 30px;
    }

    ${ButtonGroup} {
      margin-top: 30px;

      button {
        border-width: 1px;
      }

      input {
        max-width: 80px;
      }
    }
  }

  @media only screen and (max-width: 740px) {
    flex-flow: wrap;

    .product-description {
      margin-left: 0;
      margin-top: 30px;
    }
  }
`
export const OptionContainer = styled.div`
  font-family: fieldwork, sans-serif;

  h2 {
    text-transform: uppercase;
    font-size: 14px;
  }

  ${LinkButton} {
    margin-left: 4px;
  }
`

export const OptionButton = styled(Button)<{ selected?: boolean }>`
  display: inline-flex;
  height: 42px;
  width: 42px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid
    ${({ theme, selected }) => (selected ? 'transparent' : theme.colors.text)};
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.text : 'transparent'};
  color: ${({ theme, selected }) =>
    selected ? theme.colors.background : theme.colors.text};

  &:not(:last-of-type) {
    margin-right: 8px;
  }
`

export const Added = styled.span`
  font-size: 11px;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  padding: 2px 8px;

  &:before {
    background: ${({ theme }) => theme.colors.primary};
    content: ' ';
    position: absolute;
    height: 8px;
    width: 8px;
    display: block;
    transform: rotate(45deg);
    margin-left: 40px;
    margin-top: 2px;
  }
`
