import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { shuffle } from 'lodash'

import { ProductList } from '../product'

const Bestsellers = () => {
  const { t } = useTranslation()
  const { bym, allShopifyProduct } = useStaticQuery(query)
  const products = allShopifyProduct.nodes.filter(({ shopifyId }: any) =>
    bym.products.edges.map(({ node }: any) => node.id).includes(shopifyId)
  )
  return <ProductList title={t('bestsellers')} products={shuffle(products)} />
}

export default Bestsellers

const query = graphql`
  query {
    allShopifyProduct {
      nodes {
        id
        title
        handle
        productType
        description
        shopifyId
        availableForSale
        options {
          name
          values
        }
        variants {
          id
          title
          price
          compareAtPrice
          availableForSale
          shopifyId
          selectedOptions {
            name
            value
          }
          image {
            originalSrc
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        images {
          originalSrc
          id
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    bym {
      products(first: 6, sortKey: BEST_SELLING) {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`
