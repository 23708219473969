import React, { ReactNode, useContext, useState } from 'react'

import { BiCart } from '@react-icons/all-files/bi/BiCart'
import { BiHeart } from '@react-icons/all-files/bi/BiHeart'
import { BiSearch } from '@react-icons/all-files/bi/BiSearch'
import { navigate } from 'gatsby'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'

import { Wishlist, Search } from '..'
import StoreContext from '../../context/store'
import { useWishlist } from '../../hooks'
import { Notification } from '../../styled'
import { ButtonToolbar, LinkButton } from '../../styled/button'

import { ConfigToolbar, ShopNavigationContainer } from './styled'

const ShopNavigation = () => {
  const { t } = useTranslation()
  const [content, setContent] = useState<ReactNode>()
  const [redirect, setRedirect] = useState('')
  const { languages, changeLanguage, language } = useI18next()
  const { wishlist } = useWishlist()
  const {
    store: { checkout },
  } = useContext(StoreContext)
  const navItems = [
    {
      onClick: () => setRedirect(t('nav.cart.url', { defaultValue: '/cart' })),
      title: t('nav.cart.title'),
      Icon: BiCart,
      count: checkout?.lineItems?.length ?? 0,
    },
    {
      onClick: () =>
        typeof window !== `undefined` &&
        setContent(<Wishlist onClose={() => setContent(undefined)} visible />),
      title: t('nav.wishlist.title'),
      Icon: BiHeart,
      count: wishlist?.products?.length ?? 0,
    },
    {
      onClick: () =>
        setContent(<Search onClose={() => setContent(undefined)} />),
      title: t('nav.search.title'),
      Icon: BiSearch,
    },
  ]

  if (redirect) {
    navigate(redirect)
    setRedirect('')
    setContent(undefined)
  }

  return (
    <>
      <ShopNavigationContainer>
        <ButtonToolbar>
          {navItems.map(({ title, onClick, Icon, count }) => (
            <LinkButton key={title} onClick={onClick} aria-label={title}>
              {count ? (
                <Notification data-count={count}>
                  <Icon size={24} />
                </Notification>
              ) : (
                <Icon size={24} />
              )}
            </LinkButton>
          ))}
        </ButtonToolbar>
        <ConfigToolbar>
          <span>{t('bym.currency')}</span>
          <select
            onChange={e => changeLanguage(e.target.value)}
            value={language}
          >
            {languages.map(lang => (
              <option key={lang} value={lang}>
                {t(`lang.${lang}`)}
              </option>
            ))}
          </select>
        </ConfigToolbar>
      </ShopNavigationContainer>
      {content}
    </>
  )
}

export default ShopNavigation
