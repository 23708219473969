import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import { Helmet, useI18next } from 'gatsby-plugin-react-i18next'

import { URL } from '../../config'

const SEO: React.FC<{
  title: string
  description?: string
  image?: string
  path?: string
  meta?: any[]
  keywords?: string[]
}> = ({ description, image, meta = [], keywords = [], title, path = '' }) => {
  const { language: lang = 'en' } = useI18next()
  const data = useStaticQuery(detailsQuery)
  const metaDescription = description || data.site.siteMetadata.description
  const metaImage = image || `${URL}${data?.file?.childImageSharp?.fluid.src}`

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={`${data.site.siteMetadata.title} | %s`}
      meta={[
        { name: `description`, content: metaDescription },
        { property: `og:title`, content: title },
        { property: `og:url`, content: `${URL}${path}` },
        { property: `og:description`, content: metaDescription },
        { property: `og:image`, content: metaImage },
        { property: `og:type`, content: `website` },
        { name: `twitter:card`, content: `summary` },
        { name: `twitter:creator`, content: data.site.siteMetadata.author },
        { name: `twitter:site`, content: data.site.siteMetadata.author },
        { name: `twitter:title`, content: title },
        { name: `twitter:image`, content: metaImage },
        { name: `twitter:description`, content: metaDescription },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
      defer={false}
    />
  )
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    file(relativePath: { eq: "vodloye.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
