import React from 'react'

import styled from 'styled-components'

const ColorContainer = styled.span<{
  color: string
  small?: boolean
  selected?: boolean
}>`
  position: relative;
  height: ${({ small }) => (small ? 18 : 32)}px;
  width: ${({ small }) => (small ? 18 : 32)}px;
  border-radius: 50%;
  border: ${({ selected, small }) => (selected || !small ? 2 : 1)}px solid
    ${({ theme, selected }) =>
      selected ? theme.colors.text : theme.colors.lightgrey};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px;

  &:before {
    height: 100%;
    width: 100%;
    content: ' ';
    border-radius: 50%;
    background-color: ${({ color, theme }) =>
      color ? theme.outfit[color] : theme.colors.black};
  }

  &:not(:last-of-type) {
    margin-right: 4px;
  }
`

const Color: React.FC<{
  color: string
  selected?: boolean
  small?: boolean
}> = ({ color, small, selected }) => (
  <ColorContainer color={color} small={small} selected={selected} />
)

export default Color
