import styled from 'styled-components'

import { Container } from '../../../styled'
import { Button } from '../../../styled/button'

export const RainbowContainer = styled.div`
  @keyframes animateBg {
    0% {
      background-position: 0% 91%;
    }
    50% {
      background-position: 100% 10%;
    }
    100% {
      background-position: 0% 91%;
    }
  }
  animation: animateBg 2s infinite;
  color: ${({ theme }) => theme.colors.white};
  background: linear-gradient(
    260deg,
    ${({ theme }) => theme.colors.primary},
    ${({ theme }) => theme.colors.blue},
    ${({ theme }) => theme.colors.black}
  );
  background-size: 600% 600%;
  font-family: quasimoda, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
`
export const NavContainer = styled.nav`
  @keyframes bgOpacity {
    from {
      opacity: 0;
      margin-top: -60px;
    }
    to {
      opacity: 1;
      margin-top: 0;
    }
  }
  background-color: rgba(255, 255, 255, 1);
  animation: bgOpacity 0.6s linear;
  transition: all 0.6s linear;
  display: flex;
  justify-content: center;

  ${Container} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
    padding: 0 32px;
    position: sticky;
    a {
      display: inherit;
      svg {
        height: 38px;
      }
    }

    ul {
      margin: 0;
      padding-left: 64px;
      list-style: none;
      display: flex;

      li {
        &:not(:last-of-type) {
          margin-right: 16px;
        }
        button {
          font-family: quasimoda-2, quasimoda, sans-serif;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }
  }

  &.fixed {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 100000;
  }

  &.white {
    background-color: rgba(255, 255, 255, 0);
    animation: bgOpacity 0.6s linear;
    color: ${({ theme }) => theme.colors.white};
    ${Container} {
      a svg,
      button {
        fill: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }

  @media only screen and (max-width: 800px) {
    ${Container} {
      height: auto;
      padding: 16px 32px;
      ul {
        padding-left: 0;
      }
    }
  }
`
export const NavContent = styled.div`
  position: absolute;
  top:92px;
  left: 0;
  right: 0;
  z-index: 100000;
  background: ${({ theme }) => theme.colors.background};};
  
`
export const NavBody = styled.div`
  position: relative;

  &.top {
    top: 0px;
    position: sticky;
    z-index: 100000;
  }
`

export const ConfigToolbar = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;

  span {
    padding: 0 8px;
    font-size: 12px;
  }

  select {
    border: none;
    font-size: 12px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors?.white};
  }
`
export const ShopNavigationContainer = styled.div`
  background: ${({ theme }) => theme.colors?.black};
  color: ${({ theme }) => theme.colors?.white};
  display: flex;
  justify-content: flex-end;
  padding: 0 32px;

  ${Button} {
    color: ${({ theme }) => theme.colors?.white};


  ${ConfigToolbar} select {
    color: ${({ theme }) => theme.colors?.white};
  }
`
