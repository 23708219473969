import React, { useState, useEffect } from 'react'

import Client from 'shopify-buy'

import Context from '../context/store'

const client = Client.buildClient({
  storefrontAccessToken: process.env.SHOPIFY_ACCESS_TOKEN || '',
  domain: `${process.env.SHOP_NAME}`,
})

const ContextProvider = ({ children }: any) => {
  const initialStoreState = {
    client,
    adding: false,
    checkout: { lineItems: [] } as any,
    products: [],
    shop: {},
  }

  const [store, updateStore] = useState(initialStoreState)
  const [isRemoved, setIsRemoved] = useState(false)

  useEffect(() => {
    const initializeCheckout = async () => {
      // Check for an existing cart.
      const isBrowser = typeof window !== 'undefined'
      const existingCheckoutID = isBrowser
        ? localStorage.getItem('shopify_checkout_id')
        : null

      const setCheckoutInState = (checkout: any) => {
        if (isBrowser) {
          localStorage.setItem('shopify_checkout_id', checkout.id)
        }

        updateStore(prevState => ({ ...prevState, checkout }))
      }

      const createNewCheckout = () => store.client.checkout.create()
      const fetchCheckout = (id: string) => store.client.checkout.fetch(id)

      if (existingCheckoutID) {
        try {
          const checkout = await fetchCheckout(existingCheckoutID)
          // Make sure this cart hasn’t already been purchased.
          if (!isRemoved && !checkout.completedAt) {
            setCheckoutInState(checkout)
            return
          }
        } catch (e) {
          localStorage.setItem('shopify_checkout_id', '')
        }
      }

      const newCheckout = await createNewCheckout()
      if (!isRemoved) {
        setCheckoutInState(newCheckout)
      }
    }

    initializeCheckout()
  }, [isRemoved, store.client.checkout])

  useEffect(
    () => () => {
      setIsRemoved(true)
    },
    []
  )

  return (
    <Context.Provider
      value={{
        store,
        addVariantToCart: (variantId: string, quantity: any) => {
          if (variantId === '' || !quantity) return undefined

          updateStore(prevState => ({ ...prevState, adding: true }))

          const checkoutId = store.checkout.id
          const lineItemsToUpdate = [
            { variantId, quantity: parseInt(quantity, 10) },
          ]

          return store.client.checkout
            .addLineItems(checkoutId, lineItemsToUpdate)
            .then(checkout => {
              updateStore((prevState: any) => ({
                ...prevState,
                checkout,
                adding: false,
              }))
            })
        },
        removeLineItem: (
          clientInstance: any,
          checkoutID: string,
          lineItemID: string
        ) =>
          clientInstance.checkout
            .removeLineItems(checkoutID, [lineItemID])
            .then((res: any) => {
              updateStore(prevState => ({ ...prevState, checkout: res }))
            }),
        updateLineItem: (
          clientInstance: any,
          checkoutID: string,
          lineItemID: string,
          quantity: string
        ) => {
          const lineItemsToUpdate = [
            { id: lineItemID, quantity: parseInt(quantity, 10) },
          ]

          return clientInstance.checkout
            .updateLineItems(checkoutID, lineItemsToUpdate)
            .then((res: any) => {
              updateStore(prevState => ({ ...prevState, checkout: res }))
            })
        },
        addDiscount: (clientInstance: any, discountCode: string) => {
          updateStore(prevState => ({ ...prevState, updating: true }))
          return clientInstance.checkout
            .addDiscount(store.checkout.id, discountCode)
            .then((res: any) => {
              updateStore(prevState => ({
                ...prevState,
                checkout: res,
                updating: false,
              }))
            })
        },
        removeDiscount: (clientInstance: any) => {
          updateStore(prevState => ({ ...prevState, updating: true }))
          return clientInstance.checkout
            .removeDiscount(store.checkout.id)
            .then((res: any) => {
              updateStore(prevState => ({
                ...prevState,
                updating: false,
                checkout: res,
              }))
            })
        },
      }}
    >
      {children}
    </Context.Provider>
  )
}
export default ContextProvider
