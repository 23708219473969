import React, { useState } from 'react'

import { useTranslation } from 'gatsby-plugin-react-i18next'
import Markdown from 'markdown-to-jsx'
import styled from 'styled-components'

export const PopupContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.35);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 200001;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`
const PopupMessage = styled.div`
  background-image: linear-gradient(
    45deg,
    #4b46d9 8.33%,
    #3c37d2 8.33%,
    #3c37d2 50%,
    #4b46d9 50%,
    #4b46d9 58.33%,
    #3c37d2 58.33%,
    #3c37d2 100%
  );
  background-size: 8.49px 8.49px;
  color: white;
  max-width: 480px;
  width: 100%;
  box-shadow: 2px 0px 10px 0px rgba(0, 0, 0, 0.33);
  text-align: center;
  padding: 20px;

  .popup-message {
    width: 100%;
    background-color: #3c37d2;
    padding: 20px;
    h2 {
      font-family: retiro-std-24pt, sans-serif;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
      font-size: 60px;
    }

    p {
      font-size: 18px;
    }
  }
`

const Popup = () => {
  const { t } = useTranslation()
  const [popupHidden, setPopupHidden] = useState(
    typeof window !== 'undefined' && !!localStorage.getItem('bym_popup')
  )

  const props: any = {
    role: 'button',
    'aria-hidden': 'true',
    onClick: () => {
      setPopupHidden(true)
      localStorage.setItem('bym_popup', 'true')
    },
  }

  return (
    <div>
      {popupHidden ? undefined : (
        <PopupContainer {...props}>
          <PopupMessage>
            <div className="popup-message" {...props}>
              <h2>{t('sale.title', { sale: 40 })}</h2>
              <Markdown>{t('sale.description')}</Markdown>
            </div>
          </PopupMessage>
        </PopupContainer>
      )}
    </div>
  )
}

export default Popup
