import React from 'react'

import { useTranslation } from 'gatsby-plugin-react-i18next'
import { isEqual } from 'lodash'
import styled, { css } from 'styled-components'

const PriceTag = styled.span<{
  type?: 'normal' | 'reduce' | 'sale'
  large: boolean
}>`
  font-family: quasimoda, sans-serif;
  font-weight: 700;
  font-size: 11px;
  ${({ type }) =>
    type === 'normal'
      ? css`
          font-weight: bold;
        `
      : undefined}
  ${({ type }) =>
    type === 'sale'
      ? css`
          text-decoration: line-through;
          font-style: italic;
        `
      : undefined}
  ${({ type, theme }) =>
    type === 'reduce'
      ? css`
          font-weight: bold;
          color: ${theme.colors.pink};
        `
      : undefined}
  ${({ large }) =>
    large
      ? css`
          font-size: 24px;
        `
      : undefined}
`
const PriceContainer = styled.div`
  display: flex;
  ${PriceTag}:not(:last-of-type) {
    margin-right: 8px;
  }
`

const Price: React.FC<{
  price: string
  compareAtPrice?: string
  large?: boolean
}> = ({ compareAtPrice, price, large = false }) => {
  const { t } = useTranslation()
  const sale = compareAtPrice && !isEqual(price, compareAtPrice)
  return (
    <PriceContainer>
      <PriceTag type={sale ? 'reduce' : 'normal'} large={large}>
        {t('price', { amount: price })}
      </PriceTag>
      {sale ? (
        <PriceTag type="sale" large={large}>
          {t('price', { amount: compareAtPrice })}
        </PriceTag>
      ) : undefined}
    </PriceContainer>
  )
}

export default Price
