import React, { useEffect, useState } from 'react'

import { BsHeart } from '@react-icons/all-files/bs/BsHeart'
import { BsHeartFill } from '@react-icons/all-files/bs/BsHeartFill'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useTheme } from 'styled-components'

import { useWishlist } from '../../hooks'
import { LinkButton } from '../../styled/button'

const Like: React.FC<{
  currentProduct: any
  variant: any
  large?: boolean
}> = ({ currentProduct, variant, large }) => {
  const { t } = useTranslation()
  const { wishlist, add, remove } = useWishlist()
  const [product, setProduct] = useState<any>()
  const { colors }: any = useTheme()

  function handleChange(e: Event) {
    e?.preventDefault()
    if (product) {
      setProduct(undefined)
      remove(variant?.id)
    } else {
      const newProduct = {
        handle: currentProduct.handle,
        title: currentProduct.title,
        variantTitle: variant.title,
        price: variant.price,
        availableForSale: variant.availableForSale,
        compareAtPrice: variant.compareAtPrice,
        shopifyId: variant.shopifyId,
        id: variant.id,
        image: variant.image.originalSrc,
      }
      add(newProduct)
      setProduct(newProduct)
    }
  }

  useEffect(() => {
    if (variant?.id && wishlist?.products) {
      setProduct(wishlist?.products?.find(({ id }: any) => id === variant.id))
    }
  }, [variant.id, wishlist?.products])

  return (
    <LinkButton
      className="like-btn"
      onClick={handleChange}
      aria-label={t('saveProduct')}
      large={large}
    >
      {product ? <BsHeartFill style={{ fill: colors.pink }} /> : <BsHeart />}
    </LinkButton>
  )
}

export default Like
