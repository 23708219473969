import { useContext, useEffect, useState } from 'react'

import firebase from 'gatsby-plugin-firebase'

import StoreContext from '../context/store'
import sanitize from '../utils/sanitize'

import { useAuth } from './index'

export default function useWishlist() {
  const { user } = useAuth()
  const [wishlist, setWishlist] = useState<any>()
  const { products = [] } = wishlist ?? {}
  const {
    store: { adding },
  } = useContext(StoreContext)
  const remove = (idToRemove: string) => {
    const wishlistToRemove = {
      ...wishlist,
      products: products.filter(({ id }: any) => id !== idToRemove),
    }
    firebase
      .firestore()
      .collection('wishlist')
      .doc(user?.uid)
      .set(wishlistToRemove)
      .then(() => setWishlist(wishlistToRemove))
  }
  const add = (product: any) => {
    const wishlistToAdd = {
      ...wishlist,
      products: sanitize([...products, product]),
    }
    firebase
      .firestore()
      .collection('wishlist')
      .doc(user?.uid)
      .set(wishlistToAdd)
      .then(() => setWishlist(wishlistToAdd))
  }

  useEffect(() => {
    if (user?.uid && !adding)
      firebase
        .firestore()
        .doc(`wishlist/${user?.uid}`)
        .get()
        .then(doc => setWishlist(doc.data()))
  }, [user?.uid, adding])

  return { wishlist, remove, add, user }
}
