import React from 'react'

import { BiCaretRight } from '@react-icons/all-files/bi/BiCaretRight'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'

import { TitleWithBorder } from '../../styled'
import { Button } from '../../styled/button'
import { toProductJsonLd } from '../../utils/jsonld'
import Collection from '../collection'
import JsonLd from '../seo/jsonld'

import {
  ProductListContainer,
  ProductsContainer,
  ProductsFooterContainer,
} from './styled/list'

import { Preview } from '.'

const ProductList: React.FC<{
  title: string
  products: any[]
  withCollection?: any
  withGrid?: boolean
  withViewMore?: {
    type: 'link' | 'button'
    onClick?: () => void
    url?: string
    label?: string
  }
}> = ({ title, products, withGrid = false, withViewMore, withCollection }) => {
  const { t } = useTranslation()
  const displayViewMore = () => {
    const label = withViewMore?.label || t('viewMore')
    if (withViewMore?.type === 'link')
      return (
        <Link to={withViewMore?.url || ''}>
          {label} <BiCaretRight style={{ top: '2px', position: 'relative' }} />
        </Link>
      )
    return <Button onClick={withViewMore?.onClick}>{label}</Button>
  }

  return (
    <ProductListContainer>
      {products.map(product => (
        <JsonLd key={product.id}>{toProductJsonLd(product)}</JsonLd>
      ))}
      {withCollection ? (
        <Collection collection={withCollection} />
      ) : (
        <TitleWithBorder>{title}</TitleWithBorder>
      )}
      <ProductsContainer withGrid={withGrid}>
        {products?.map(product => (
          <Preview key={product.id} product={product} />
        ))}
      </ProductsContainer>
      <ProductsFooterContainer>
        {withViewMore ? displayViewMore() : undefined}
      </ProductsFooterContainer>
    </ProductListContainer>
  )
}

export default ProductList
