import React, { useRef } from 'react'

import { BiX } from '@react-icons/all-files/bi/BiX'
import { graphql, useStaticQuery } from 'gatsby'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import { uniq, kebabCase } from 'lodash'
import { useClickAway } from 'react-use'
import styled from 'styled-components'

import { Container } from '../../styled'
import { CloseButton } from '../../styled/button'
import Collection from '../collection'

const ShopMenuContainer = styled.div`
  padding: 20px;
  font-size: 13px;
  column-count: 2;

  h2 {
    font-family: quasimoda-2, quasimoda, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 0;
    font-size: 16px;
    font-weight: 600;
  }

  a {
    color: ${({ theme }) => theme.colors.text};
  }

  ul {
    padding: 0;
    list-style: none;
  }
`
const ShopContainer = styled(Container)`
  position: relative;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  padding: 20px;

  @media only screen and (max-width: 740px) {
    grid-template-columns: 1fr;
    background-color: ${({ theme }) => theme.colors.background};
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    z-index: 200000;
    overflow: auto;
    text-transform: uppercase;

    ${ShopMenuContainer} {
      column-count: 1;
      font-size: 18px;

      h2 {
        font-size: 20px;
      }
    }

    ${CloseButton} {
      position: absolute;
      right: 0;
    }
  }
`

const Shop: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const ref = useRef(null)
  const { t } = useTranslation()
  const { allShopifyProduct, allShopifyCollection } = useStaticQuery(graphql`
    query {
      allShopifyProduct(sort: { order: ASC, fields: productType }) {
        nodes {
          productType
        }
      }
      allShopifyCollection(sort: { order: ASC, fields: title }) {
        nodes {
          title
          handle
          image {
            src
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)
  const types: string[] = uniq(
    allShopifyProduct.nodes.map(
      ({ productType }: { productType: string }) => productType
    )
  )
  const vodloye = allShopifyCollection.nodes?.find(
    ({ title }: any) => title === 'VOD-LOYE'
  )

  useClickAway(ref, (event: any) => {
    if (event?.target?.dataset?.type !== 'shop-btn') onClick()
  })

  return (
    <ShopContainer ref={ref}>
      <CloseButton onClick={onClick} className="small-only">
        <BiX />
      </CloseButton>
      <ShopMenuContainer>
        <div>
          <h2>{t('type.title')}</h2>
          <ul aria-label={t('type.title')}>
            {types?.map((type, index) => (
              <li key={type}>
                <Link to={`/shop/${kebabCase(type)}`} onClick={onClick}>
                  <span>{t(`type.${type}`, { defaultValue: type })}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h2>{t('collection.title')}</h2>
          <ul aria-label={t('collection.title')}>
            {allShopifyCollection.nodes?.map(({ title, handle }: any) => (
              <li key={title}>
                <Link to={`/shop/${handle}`} onClick={onClick}>
                  {title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <Link to="/shop" onClick={onClick}>
            <strong>{t('seeAll')}</strong>
          </Link>
        </div>
      </ShopMenuContainer>
      <Collection collection={vodloye} onClick={onClick} />
    </ShopContainer>
  )
}

export default Shop
