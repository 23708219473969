import React from 'react'

import { FiMail } from '@react-icons/all-files/fi/FiMail'
import { GrFacebook } from '@react-icons/all-files/gr/GrFacebook'
import { GrInstagram } from '@react-icons/all-files/gr/GrInstagram'
import { GrPinterest } from '@react-icons/all-files/gr/GrPinterest'
import { GrYoutube } from '@react-icons/all-files/gr/GrYoutube'
import { graphql, useStaticQuery } from 'gatsby'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

import { Container, Photo, Title, TitleWithBorder } from '../../styled'

import Subscription from '../subscribe/subscription'

import Amex from './assets/amex.svg'
import Apple from './assets/apple.svg'
import Mastercard from './assets/mastercard.svg'
import Paypal from './assets/paypal.svg'
import Visa from './assets/visa.svg'

const FooterBody = styled.div`
  display: flex;
  flex-flow: row wrap;
  text-align: left;
  justify-content: space-between;
  margin-top: 20px;

  a {
    color: ${({ theme }) => theme.colors.background};
  }

  span.contact {
    margin: 10px 0 20px;
    display: flex;
    align-items: center;
    font-weight: bold;
    svg {
      margin-right: 4px;
    }
  }

  ul {
    padding: 0;
    li {
      list-style: none;
      padding: 0;
    }
  }

  .social,
  .about {
    max-width: 400px;
    min-width: 300px;
    width: 100%;
    margin-bottom: 30px;
  }
  .about {
    max-width: 600px;

    blockquote {
      background: #272727;
      margin: 0;
      padding: 20px;
      border: 1px solid #353535;
      border-radius: 5px;

      img {
        border-radius: 5px;
        width: 100%;
        filter: brightness(0.9) contrast(1.1);
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  .about li {
    text-transform: uppercase;
    font-size: 11px;
  }

  .social ul:not(.shipping) {
    margin: 10px 0 20px;

    li {
      font-size: 32px;
      display: inline-block;

      &:not(:last-of-type) {
        margin-right: 10px;
      }
    }
  }

  .methods-of-payment {
    display: flex;
    margin-top: 20px;

    svg {
      height: 35px;
      &:not(:last-of-type) {
        margin-right: 4px;
      }
    }
  }

  ${Photo} {
    margin-top: 10px;
    margin-bottom: 20px;
    max-width: 250px;
  }
`

const FooterContainer = styled.footer`
  padding: 60px;
  background: ${({ theme }) => theme.colors.text};
  color: ${({ theme }) => theme.colors.background};
  fill: ${({ theme }) => theme.colors.background};

  @media only screen and (max-width: 740px) {
    padding: 20px;
  }

  ${Container} {
    padding: 8px 20px;

    ${Title} {
      color: ${({ theme }) => theme.colors.background};
    }
  }
`

const Footer = () => {
  const { t } = useTranslation()
  const { allShopifyShopPolicy } = useStaticQuery(graphql`
    query {
      allShopifyShopPolicy {
        nodes {
          type
          title
          handle
        }
      }
      file(relativePath: { eq: "bym2.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const socials = [
    {
      icon: <GrFacebook />,
      label: 'Facebook',
      url: 'https://www.facebook.com/bgbymgeneration',
    },
    {
      icon: <GrInstagram />,
      label: 'Instagram',
      url: 'https://www.instagram.com/bymgeneration_officiel/',
    },
    {
      icon: <GrYoutube />,
      label: 'Youtube',
      url: 'https://www.youtube.com/channel/UCN4Gkgy2ijWojfr1ITIi9PA',
    },
    {
      icon: <GrPinterest />,
      label: 'Pinterest',
      url: 'https://www.pinterest.com/bymgeneration/',
    },
  ]

  return (
    <FooterContainer>
      <Container>
        <FooterBody>
          <div className="social">
            <Title>{t('followUsOn')}</Title>
            <ul>
              {socials.map(({ label, icon, url }) => (
                <li key={label}>
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    {icon}
                  </a>
                </li>
              ))}
            </ul>
            <Title>{t('contactUs')}</Title>
            <span className="contact">
              <FiMail />{' '}
              <a href="mailto:bonjour@bymparis.com">bonjour@bymparis.com</a>
            </span>
            <Title>{t('shipping.title')}</Title>
            <span>{t('shipping.description')}</span>
            <span className="methods-of-payment">
              <Amex />
              <Apple />
              <Mastercard />
              <Visa />
              <Paypal />
            </span>
            <span>{t('payment.secure')}</span>
          </div>
          <div className="about">
            <TitleWithBorder>{t('subscribe.title')}</TitleWithBorder>
            <br />
            <Subscription />
            <ul>
              <li>
                <a href="/become-an-ambassador" target="_blank">
                  {t('ambassador.title')}
                </a>
              </li>
              {allShopifyShopPolicy.nodes.map(
                ({ handle, title, type }: any) => (
                  <li key={type}>
                    <Link to={`/${handle}`}>
                      {t(type, { defaultValue: title })}
                    </Link>
                  </li>
                )
              )}
              <li>
                <Link to="/disclaimer">{t('disclaimer')}</Link>
              </li>
            </ul>
          </div>
        </FooterBody>
        <div
          className="trustpilot-widget"
          data-locale="en-GB"
          data-template-id="5419b6a8b0d04a076446a9ad"
          data-businessunit-id="5fc2d2dafbe9b10001db2648"
          data-style-height="24px"
          data-style-width="100%"
          data-theme="dark"
        >
          <a
            href="https://uk.trustpilot.com/review/bymparis.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Trustpilot
          </a>
        </div>
        <Title
          style={{ textAlign: 'center', marginTop: '30px', fontWeight: 100 }}
        >
          © BYM GENERATION 2017 - From Paris
        </Title>
      </Container>
    </FooterContainer>
  )
}

export default Footer
