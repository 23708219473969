import styled, { css } from 'styled-components'

import { Container } from '../../../styled'

import { PreviewContainer } from './preview'

export const ProductListContainer = styled(Container)`
  padding: 30px;
  text-align: center;
`
export const ProductsContainer = styled.div<{ withGrid: boolean }>`
  margin-top: 30px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: ${({ withGrid }) =>
    (withGrid && 'repeat(12, 1fr)') || `1fr 1fr 1fr`};
  grid-template-rows: ${({ withGrid }) => (withGrid && 'repeat(4, 1fr)') || ``};

  ${({ withGrid }) =>
    withGrid &&
    css`
      @media only screen and (min-width: 740px) {
        ${PreviewContainer} {
          grid-column: span 3;
          grid-row: span 2;

          &:first-of-type {
            grid-column: span 6;
            grid-row: span 4;
          }
        }
      }
    `};

  @media only screen and (max-width: 740px) {
    grid-template-columns: 1fr 1fr;

    ${({ withGrid }) =>
      withGrid &&
      css`
        ${PreviewContainer}:last-of-type {
          grid-column: span 2;
          grid-row: span 2;
        }
      `};
  }
`

export const ProductsFooterContainer = styled.div`
  margin-top: 30px;
  text-align: right;
  padding: 0 16px;
  text-transform: uppercase;

  a {
    font-size: 13px;
  }
`
