import React, { useEffect } from 'react'

import { createGlobalStyle } from 'styled-components'

import { Footer, Nav } from '../components'
import { useAuth } from '../hooks'
import ContextProvider from '../provider/context'
import { MainContainer, StoreContainer } from '../styled'
import { LinkButton } from '../styled/button'

const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
  body {
    margin: 0;
    font-feature-settings: 'liga', 'kern';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${({ theme }) => theme?.colors?.text};
    background-color: ${({ theme }) => theme?.colors?.background};
    font-family: fieldwork, sans-serif;
    font-size: 16px;

    * {
      box-sizing: border-box;
    }

    a {
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: quasimoda, sans-serif;
      font-weight: 700;
    }

    h1 {
      font-size: 48px;
    }
    
    input, button, select {
      font-family: fieldwork, sans-serif;
    }

    input {
      padding: 0 16px;
      min-height: 32px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-width: 1px;
      border-color: ${({ theme }) => theme.colors.text};
    }

    select {
      background-color: transparent;
    }

    ${LinkButton} {
      &:hover {
        color: ${({ theme }) => theme.colors.primary};
      }
      &:hover:active {
        color: ${({ theme }) => theme.colors.blue};
      }
      &:focus {
        outline: none;
        border: none;
      }
    }
    .small-only {
      display: none;
    }
    .hidden {
      display: none;
    }

    @media only screen and (max-width: 740px) {
      .large-only {
        display: none;
      }
      .small-only {
        display: inherit;
      }
    }
  }

  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
`

declare global {
  interface Window {
    addthis: any
  }
}

const Layout: React.FC<{ path: string }> = ({ children, path }) => {
  useAuth()

  useEffect(() => {
    if (typeof window !== `undefined` && window?.addthis)
      window?.addthis?.update?.()
  }, [path])

  return (
    <ContextProvider>
      <GlobalStyle />
      <MainContainer>
        <Nav
          fixed={
            ['/fr/', '/en/', '/it/', '/pt/', '/es/'].includes(path) ||
            path === '/'
          }
        />
        <StoreContainer>{children}</StoreContainer>
      </MainContainer>
      <Footer />
    </ContextProvider>
  )
}

export default Layout
