const outfit = {
  Orange: '#ee5c0a',
  Grey: '#7f8283',
  Green: '#8fd380',
  'Bleu Marine': '#4b70fb',
  'Bleu Saphir': '#273d76',
  Beige: '#fff1e6',
  Black: '#000',
  Noir: '#000',
  'Argenté-Noir': '#212529',
  Blanc: '#fff',
  Sand: '#fdccd9',
  Rose: '#fdccd9',
  White: '#fff',
  Vert: '#416746',
  Yellow: '#bff054',
}

const colors = {
  black: '#0c0f0aff',
  grey: '#3a3a3aff',
  lightgrey: '#c3c3c3ff',
  lightgrey2: '#f0f0f0ff',
  lightgrey3: '#fafafaff',
  pink: '#ff206eff',
  yellow: '#fbff12ff',
  primary: '#4b70fb',
  blue: '#273d76',
  white: '#ffffffff',
}

export const lightTheme = {
  outfit,
  colors: {
    ...colors,
    text: '#0c0f0aff',
    background: '#ffffffff',
    background90: '#fffffff2',
  },
}
export const darkTheme = {
  outfit,
  colors: {
    ...colors,
    text: '#ffffffff',
    background: '#0c0f0aff',
    background90: '#0c0f0af2',
  },
}
