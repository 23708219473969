import React, { useContext, useEffect, useState } from 'react'

import { BiPlus } from '@react-icons/all-files/bi/BiPlus'
import { BiX } from '@react-icons/all-files/bi/BiX'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

import StoreContext from '../../context/store'
import { Tag, Title } from '../../styled'

import { Button, CloseButton } from '../../styled/button'

import Color from './color'

import Price from './price'

const CardContainer = styled.div`
  align-items: center;
  position: relative;
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }

  ${CloseButton} {
    position: absolute;
    right: 0;
  }
`
CardContainer.defaultProps = { className: 'bym-product-card' }

const CardBody = styled.div`
  display: flex;

  img {
    max-width: 80px;
    margin-right: 8px;
  }

  h2 {
    margin: 0;
  }

  .card-description {
    display: flex;
    align-items: center;
  }

  ${Button}, input {
    margin-top: 20px;
  }
`

const Card: React.FC<{
  product: ProductCart
  onClose?: () => void
  onDelete?: () => void
  withAdd?: boolean
  withQuantity?: boolean
}> = ({ product, onClose, withAdd, onDelete, withQuantity }) => {
  const { t } = useTranslation()
  const {
    addVariantToCart,
    removeLineItem,
    updateLineItem,
    store: { client, checkout },
  } = useContext(StoreContext)
  const [quantity, setQuantity] = useState(1)

  const remove = () => {
    removeLineItem(client, checkout.id, product?.id)
  }
  const changeQuantity = (newQuantity: number) => {
    setQuantity(newQuantity)
    updateLineItem(client, checkout.id, product?.id, newQuantity)
    onClose?.()
  }
  const add = () => {
    addVariantToCart(product.shopifyId, 1)
    onClose?.()
  }
  const [size, color] = product.variantTitle.split(' / ')

  useEffect(() => {
    if (product?.quantity) setQuantity(product.quantity)
  }, [product])

  return (
    <CardContainer>
      {onDelete || withQuantity ? (
        <CloseButton onClick={() => onDelete?.() ?? remove()} small>
          <BiX />
        </CloseButton>
      ) : undefined}
      <CardBody>
        <a href={`/shop/${product.handle}`}>
          <img
            src={product.image}
            alt={product.title}
            title={product.title}
            width={80}
          />
        </a>
        <div>
          <a href={`/shop/${product.handle}`}>
            <Title>{product.title}</Title>
          </a>
          <div className="card-description">
            {product.availableForSale ? undefined : (
              <Tag danger>{t('outOfStock')}</Tag>
            )}
            <Tag>{size}</Tag>
            <span style={{ marginRight: '8px' }}>
              <Color color={color} small />
            </span>
            <Price
              price={product.price}
              compareAtPrice={product.compareAtPrice}
            />
          </div>
          {withQuantity ? (
            <input
              type="number"
              min="1"
              onChange={(e: any) => changeQuantity(e.target.value)}
              value={quantity}
            />
          ) : undefined}
          {withAdd ? (
            <Button
              appearance="ghost"
              onClick={add}
              disabled={!product.availableForSale}
              aria-label={t('wishlist.add')}
              block
            >
              <BiPlus /> {t('wishlist.add')}
            </Button>
          ) : undefined}
        </div>
      </CardBody>
    </CardContainer>
  )
}

export default Card
