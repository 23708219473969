import styled from 'styled-components'

export const Notification = styled.span`
  display: flex;
  &::after {
    content: attr(data-count);
    font-family: quasimoda, sans-serif;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    height: 14px;
    width: 14px;
    display: flex;
    margin-left: -13px;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    padding: 0;
    font-weight: 600;
  }
`

export const Photo = styled.div`
  @media only screen and (max-width: 740px) {
    max-width: 100%;
  }

  img {
    width: 100%;
    filter: brightness(0.9) contrast(1.1);
  }

  .iiz__btn.iiz__hint {
    display: none;
  }
`

export const Container = styled.div`
  max-width: 1368px;
  width: 100%;
  margin: auto;
`
export const TopContainer = styled.div`
  position: fixed;
`
export const StoreContainer = styled.div``
export const MainContainer = styled.div``

export const Tag = styled.span<{ danger?: boolean }>`
  background-color: ${({ danger, theme }) =>
    danger ? theme.colors.pink : theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  font-size: 10px;
  font-weight: bold;
  padding: 0 8px;
  text-transform: uppercase;
  border-radius: 2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 18px;

  &:not(:last-of-type) {
    margin-right: 8px;
  }
`
export const Description = styled.blockquote`
  padding: 30px;
  margin: 0;
  border-top: 20px solid ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.grey};
  font-family: fieldwork, sans-serif;

  h2 {
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
    font-family: quasimoda-2, quasimoda, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 0;
    font-size: 24px;
  }
`

export const Breadcrumb = styled.span`
  font-family: quasimoda-2, quasimoda, sans-serif;
  font-weight: bold;
  position: relative;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  height: 38px;
  font-size: 18px;
  display: flex;
  padding: 0 16px;
  align-items: center;
  margin-right: 20px;
  text-transform: uppercase;
  margin-bottom: 20px;

  a {
    color: ${({ theme }) => theme.colors.white};

    &:hover {
      text-decoration: none;
    }
  }

  &:after {
    content: ' ';
    height: 28px;
    width: 27px;
    top: 5px;
    right: -13px;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.black};
    z-index: -1;
    transform: rotate(45deg);
  }
`

export const Slider = styled.div`
  background: ${({ theme }) => theme.colors.black};
  margin: auto;
  overflow-x: hidden;
  min-height: 500px;
  padding: 0;

  img {
    width: 100%;
    filter: brightness(0.9) contrast(1.1);
  }
  .slick-dots {
    position: absolute;
    bottom: 30px;

    li.slick-active button:before,
    button:before {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.white};
    }
  }
  .slick-arrow {
    position: absolute;
    z-index: 100;
    &:before {
      color: ${({ theme }) => theme.colors.text};
    }
    &.slick-prev {
      left: 30px;
    }
    &.slick-next {
      right: 30px;
    }
  }

  .iiz__btn.iiz__hint {
    display: none;
  }
`

export const TitleWithBorder = styled.span`
  font-family: quasimoda-2, quasimoda, sans-serif;
  font-size: 24px;
  font-weight: bold;
  padding: 4px 0px;
  text-transform: uppercase;
  text-align: center;
`

export const Title = styled.span`
  font-family: quasimoda, sans-serif;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.33px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
`
