import React, { useState } from 'react'

import { BiX } from '@react-icons/all-files/bi/BiX'
import { graphql, useStaticQuery } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

import { Container, Title } from '../../styled'
import { CloseButton } from '../../styled/button'
import normalize from '../../utils/normalize'
import { List } from '../index'

const SearchContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: 100%;
  z-index: 200000;
  overflow: auto;

  ${Container} {
    display: flex;
    flex-direction: column;

    .search-header {
      top: 0;
      position: sticky;
      text-align: center;
      z-index: 2;
      padding: 20px;
      background-color: ${({ theme }) => theme.colors.background};

      ${CloseButton} {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    input {
      width: 100%;
      margin: 8px 0;
    }

    .search-preview {
      padding: 20px;
      flex-grow: 1;
      z-index: 1;
    }
  }
`

const Search: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation()
  const { allShopifyProduct } = useStaticQuery(query)
  const [search, setSearch] = useState('')
  const onSearch = (product: any) => {
    const productText = normalize(product.title).toLowerCase()
    if (search.length >= 2) {
      return productText.includes(search)
    }
    return false
  }
  const toRow = (product: any) => {
    const [variant] = product?.variants ?? []

    return {
      handle: product.handle,
      title: product.handle,
      variantTitle: variant.title,
      price: variant.price,
      availableForSale: variant.availableForSale,
      compareAtPrice: variant.compareAtPrice,
      id: product.id,
      image: variant.image.originalSrc,
    }
  }

  return (
    <SearchContainer>
      <Container>
        <div className="search-header">
          <label htmlFor="search">
            <Title>{t('search.for')}</Title>
          </label>
          <CloseButton onClick={onClose}>
            <BiX />
          </CloseButton>
          <input
            id="search"
            type="search"
            onChange={(e: any) => setSearch(e.target.value.toLowerCase())}
            placeholder={t('search.enterSomething')}
          />
        </div>
        <div className="search-preview">
          <List
            empty={t('search.empty')}
            products={
              allShopifyProduct.nodes?.filter(onSearch).map(toRow) ?? []
            }
          />
        </div>
      </Container>
    </SearchContainer>
  )
}

export default Search

export const query = graphql`
  {
    allShopifyProduct(sort: { order: DESC, fields: publishedAt }) {
      nodes {
        id
        title
        handle
        productType
        description
        shopifyId
        availableForSale
        options {
          name
          values
        }
        variants {
          id
          title
          price
          compareAtPrice
          availableForSale
          shopifyId
          selectedOptions {
            name
            value
          }
          image {
            originalSrc
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        images {
          originalSrc
          id
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
