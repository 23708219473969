import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import { ProductList } from '../product'

const Recommendations: React.FC<{ recommendations: any[] }> = ({
  recommendations,
}) => {
  const { t } = useTranslation()
  const { allShopifyProduct } = useStaticQuery(query)
  const products = allShopifyProduct.nodes.filter(({ shopifyId }: any) =>
    recommendations.map(({ id }: any) => id).includes(shopifyId)
  )
  return <ProductList title={t('seeSimilar')} products={products.slice(0, 3)} />
}

export default Recommendations

const query = graphql`
  query {
    allShopifyProduct {
      nodes {
        id
        title
        handle
        productType
        description
        shopifyId
        availableForSale
        options {
          name
          values
        }
        variants {
          id
          title
          price
          compareAtPrice
          availableForSale
          shopifyId
          selectedOptions {
            name
            value
          }
          image {
            originalSrc
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        images {
          originalSrc
          id
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
