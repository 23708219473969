import styled, { css } from 'styled-components'

export const Button = styled.button<{
  appearance?: 'primary' | 'ghost' | 'link'
  active?: boolean
  block?: boolean
  reverse?: boolean
  large?: boolean
}>`
  cursor: pointer;
  padding: 4px 16px;
  min-height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;

  ${({ large }) =>
    large
      ? css`
          font-size: 24px;
        `
      : undefined}
  ${({ block }) =>
    block
      ? css`
          width: 100%;
        `
      : undefined}
  ${({ active, appearance }) =>
    active && appearance === 'link'
      ? css`
          color: ${({ theme }) => theme.colors.blue};
        `
      : undefined}

  ${({ appearance, theme }) =>
    appearance === 'link'
      ? css`
          padding: 0;
          background-color: transparent;
          border: none;
          height: auto;
        `
      : (appearance === 'ghost' &&
          css`
            background-color: transparent;
            color: ${theme.colors.text};
            fill: ${theme.colors.text};
            text-align: center;
            font-weight: bold;
            border: 1px solid ${theme.colors.text};
          `) ||
        css`
          background-color: ${theme.colors.text};
          color: ${theme.colors.background};
          fill: ${theme.colors.background};
          text-align: center;
          font-weight: bold;
          border: none;
        `}
  
  ${({ reverse }) =>
    reverse
      ? css`
          background-color: ${({ theme }) => theme.colors.background};
          color: ${({ theme }) => theme.colors.text};
          fill: ${({ theme }) => theme.colors.text};
        `
      : undefined}

  &:disabled {
    background-color: ${({ theme }) => theme.colors.lightgrey};
    cursor: not-allowed;
  }

  svg {
    margin-right: 8px;
  }
`
Button.defaultProps = { appearance: 'primary', type: 'button' }
export const LinkButton = styled(Button)``
LinkButton.defaultProps = { ...Button.defaultProps, appearance: 'link' }

export const ButtonToolbar = styled.div`
  display: flex;
  align-items: center;
  ${Button}:not(:last-of-type) {
    margin-right: 8px;
  }
`
export const ButtonGroup = styled.div`
  display: inline-flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.text};
  border-radius: 4px;
  overflow: hidden;

  input {
    border: 0;

    &:focus {
      outline: none;
    }
  }
`
export const CloseButton = styled(LinkButton)<{ small?: boolean }>`
  font-size: ${({ small }) => (small ? 24 : 32)}px;
`
