import React, { useState } from 'react'

import axios from 'axios'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

import { Button, ButtonGroup } from '../../styled/button'

const SubscribeButtonGroup = styled(ButtonGroup)`
  input,
  button {
    min-height: 42px;
  }

  input {
    max-width: 260px;
    width: 100%;
  }

  button {
    background: rgb(222, 255, 147);
    color: ${({ theme }) => theme.colors.black};
  }
`

const Subscription = () => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [sent, setSent] = useState('')

  const subscribe = async () => {
    try {
      await axios.post('/api/subscribe', { email })
      setSent(t('subscribe.sent'))
    } catch (e) {
      setSent(t('subscribe.sentFailed'))
    }
    setTimeout(() => {
      setSent('')
    }, 10000)
  }
  return (
    <>
      <SubscribeButtonGroup>
        <input
          type="email"
          placeholder={t('subscribe.enterEmail')}
          onChange={(e: any) => setEmail(e.target.value)}
          value={email}
        />
        <Button
          onClick={() => {
            subscribe()
          }}
          disabled={!email}
        >
          {t('subscribeToBym')}
        </Button>
      </SubscribeButtonGroup>
      <p className="message">{sent}</p>
    </>
  )
}

export default Subscription
