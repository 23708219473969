import { Link } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

export const PreviewContainer = styled(Link)`
  position: relative;
  color: ${({ theme }) => theme.colors.text};
  text-align: left;

  img {
    transition: all 0.3s linear !important;
    filter: brightness(0.9) contrast(1.1);
  }

  img:hover,
  &:hover img {
    transform: scale(1.3);
  }

  .like-btn {
    z-index: 100;
    position: absolute;
    top: 20px;
    right: 20px;

    @media only screen and (max-width: 740px) {
      top: 10px;
      right: 10px;
      font-size: 16px;
    }
  }
`
export const PreviewBody = styled.div``
export const PreviewDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;

  .color-container {
    margin-right: 8px;
  }
`
