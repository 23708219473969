import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import { Container } from '../../styled'

const NewsletterContainer = styled(Container)``

const Newsletter = () => {
  const { file } = useStaticQuery(graphql`
    query {
      shopifyCollection(title: { eq: "VOD-LOYE" }) {
        title
        image {
          src
          localFile {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      file(relativePath: { eq: "vodloye2.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <NewsletterContainer
      style={{ backgroundImage: `url${file?.childImageSharp}` }}
    >
      ok
    </NewsletterContainer>
  )
}

export default Newsletter
