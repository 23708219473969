import React, { useRef, useState } from 'react'

import { BiX } from '@react-icons/all-files/bi/BiX'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useClickAway } from 'react-use'
import styled from 'styled-components'

import { List } from '..'
import { useWishlist } from '../../hooks'
import { Title } from '../../styled'
import { CloseButton } from '../../styled/button'

const WishlistContainer = styled.div<{ visible: boolean }>`
  @keyframes slide {
    from {
      right: -380px;
    }
    to {
      right: 0;
    }
  }
  background-color: ${({ theme }) => theme.colors.white};
  position: fixed;
  right: ${({ visible }) => (visible ? 0 : '-380px')};
  left: auto;
  bottom: 0;
  top: 0;
  max-width: 380px;
  width: 100%;
  z-index: 100001;
  box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.33);
  transition: all 0.3s linear;
  animation: slide 0.3s linear;
  overflow: auto;

  .bym-product-card {
    color: ${({ theme }) => theme.colors.black};
    margin-top: 8px;

    a {
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .bym-list {
    overflow: auto;
    flex-grow: 1;
    padding: 16px;
  }

  .wishlist-header {
    padding: 8px 16px;

    ${Title} {
      text-align: center;
    }

    ${CloseButton} {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  p {
    padding: 0 16px;
  }
`

const Wishlist: React.FC<{ onClose: () => void; visible: boolean }> = ({
  onClose,
  visible: isVisible,
}) => {
  const ref = useRef(null)
  const { t } = useTranslation()
  const [visible, setVisible] = useState(isVisible)
  const { wishlist, remove } = useWishlist()
  const hide = () => {
    setVisible(false)
    setTimeout(() => {
      onClose()
    }, 600)
  }

  useClickAway(ref, hide)

  return (
    <WishlistContainer ref={ref} visible={visible}>
      <div className="wishlist-header">
        <Title>{t('nav.wishlist.title')}</Title>
        <CloseButton onClick={hide}>
          <BiX />
        </CloseButton>
      </div>
      <List
        products={wishlist?.products ?? []}
        onDelete={id => remove(id)}
        onClose={hide}
        empty={t('wishlist.empty')}
        withAdd
      />
    </WishlistContainer>
  )
}

export default Wishlist
