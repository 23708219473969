import React from 'react'

import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

import { TitleWithBorder } from '../../styled'

const NewCollection = styled.div`
  position: relative;
  min-height: 300px;
  padding: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  flex-flow: column;

  .overlay {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    transform: scale(1.3);
    background-position: center 30%;
    filter: brightness(0.9) contrast(1.1) blur(1px);
    transition: all 0.3s linear;
  }

  &:hover .overlay {
    filter: brightness(0.9) contrast(1.1) blur(0);
    background-position: center 10%;
    transform: scale(1.5);
  }

  ${TitleWithBorder} {
    z-index: 2;
    color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.white};
  }

  .collection {
    z-index: 3;
    font-family: retiro-std-24pt, sans-serif;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
    font-size: 60px;
    color: ${({ theme }) => theme.colors.white};
  }
`

const Collection: React.FC<{ collection: any; onClick?: () => void }> = ({
  collection,
  onClick,
}) => {
  const { t } = useTranslation()
  return (
    <Link to={`/shop/${collection?.handle}`} onClick={() => onClick?.()}>
      <NewCollection>
        <div
          className="overlay"
          style={{ backgroundImage: `url(${collection?.image?.src})` }}
        />
        <TitleWithBorder>
          {t('shopCollection', { name: collection?.title })}
        </TitleWithBorder>
        <span className="collection">{collection?.title}</span>
      </NewCollection>
    </Link>
  )
}

export default Collection
