import React from 'react'

import styled from 'styled-components'

import { Container } from '../../styled'
import { ProductList } from '../product'

const SaleContainer = styled.div`
  background: linear-gradient(
    180deg,
    rgba(60, 55, 210, 1) 50%,
    rgba(255, 235, 90, 1) 50%
  );
  padding: 60px;

  .sales-container {
    display: flex;
    align-items: center;
    margin: auto;

    .sales-product-container {
      background-color: #fff;
      margin-right: 30px;
      padding: 20px;
    }

    .sales-info-container {
      max-width: 800px;
    }

    @media only screen and (max-width: 900px) {
      flex-flow: column-reverse;

      .sales-product-container {
        margin-right: 0;
        margin-top: 30px;
      }
    }
  }

  video {
    display: block;
    width: 100%;
  }
`

const Sale: React.FC<{
  products: any[]
  title: string
  background?: string
  withViewMore?: {
    type: 'link' | 'button'
    onClick?: () => void
    url?: string
    label?: string
  }
}> = ({ background, children, products, title, withViewMore }) => (
  <SaleContainer style={{ background }}>
    <Container className="sales-container">
      <div className="sales-product-container">
        <ProductList
          title={title}
          products={products}
          withViewMore={withViewMore}
        />
      </div>
      <div className="sales-info-container">{children}</div>
    </Container>
  </SaleContainer>
)

export default Sale
