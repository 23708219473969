import styled from 'styled-components'

export const IntroContainer = styled.div`
  display: block;
  max-height: 600px;
  overflow: hidden;
  position: relative;
  align-self: center;

  p {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 60px;
    color: ${({ theme }) => theme.colors.white};
    text-transform: uppercase;

    .discover {
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
    }

    .collection {
      font-family: retiro-std-24pt, sans-serif;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
      font-size: 60px;
      margin-top: 30px;

      @media only screen and (max-width: 740px) {
        font-size: 24px;
      }
    }
  }

  video {
    filter: brightness(0.6) contrast(1.1);
    width: 100%;
  }

  @media only screen and (max-width: 740px) {
    p {
      padding-bottom: 40px;
    }
  }
`
