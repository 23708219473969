import React from 'react'
import { ThemeProvider } from 'styled-components'
import 'firebase/auth'
import 'firebase/firestore'

import { darkTheme, lightTheme } from './src/utils/themes'

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
export const wrapRootElement = ({ element }) => {
  const isDarkTheme =
    typeof window !== 'undefined' && localStorage.getItem('bym-theme-dark')

  return (
    <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
      {element}
    </ThemeProvider>
  )
}
