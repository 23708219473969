import React from 'react'

import Image from 'gatsby-image'
import { kebabCase } from 'lodash'

import { Title } from '../../styled'
import Like from '../wishlist/like'

import Color from './color'
import Price from './price'
import {
  PreviewContainer,
  PreviewDescriptionContainer,
  PreviewBody,
} from './styled/preview'

const Preview: React.FC<{ product: any }> = ({ product }) => {
  const [image] = product.images
  const option = product?.options?.find(({ name }: any) => name === 'Color')
  const [initialVariant] = product?.variants
  return (
    <PreviewContainer
      to={`/shop/${kebabCase(product.productType)}/${product.handle}`}
    >
      <Like currentProduct={product} variant={initialVariant} large />
      <Image
        fluid={image?.localFile?.childImageSharp?.fluid}
        alt={product.title}
        title={product.title}
      />
      <PreviewBody>
        <Title property="name">{product.title}</Title>
        <PreviewDescriptionContainer>
          <div className="color-container">
            {option?.values?.map((color: string) => (
              <Color key={color} color={color} small />
            ))}
          </div>
          <Price
            price={initialVariant?.price}
            compareAtPrice={initialVariant?.compareAtPrice}
          />
        </PreviewDescriptionContainer>
      </PreviewBody>
    </PreviewContainer>
  )
}

export default Preview
