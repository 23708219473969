import React from 'react'

import styled from 'styled-components'

import { Card } from '../product'

const ListContainer = styled.div``
ListContainer.defaultProps = { className: 'bym-list' }

const List: React.FC<{
  products: ProductCart[]
  empty?: string
  onClose?: () => void
  onDelete?: (id: string) => void
  withAdd?: boolean
  withQuantity?: boolean
}> = ({ empty, products, onClose, onDelete, withAdd, withQuantity }) => (
  <ListContainer>
    {products.length === 0 && empty ? <p>{empty}</p> : undefined}
    {products.map((product, index) => (
      <Card
        key={`${index}_${product.shopifyId}`}
        product={product}
        withAdd={withAdd}
        withQuantity={withQuantity}
        onDelete={onDelete ? () => onDelete?.(product?.id) : undefined}
        onClose={onClose}
      />
    ))}
  </ListContainer>
)

export default List
